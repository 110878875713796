
.xgs-map {
  &__container {
    width: 100%;
    height: 600px;
  }

  &__marker {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
