@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-site__menu-wrapper {
  width: $side-menu-width-desktop;

  @include tablet {
    width: $side-menu-width-tablet;
  }  

  @include mobile {
    width: $side-menu-width-mobile;
  }

  &--opened {
    margin-left: 0;
    
    @include tablet {
      transition: margin 0.2s ease-in-out;
    }
  }

  &:not(.xgs-site__menu-wrapper--opened) {
    @include tablet {
      margin-left: -$side-menu-width-tablet;
      transition: margin 0.2s ease-in-out;
    }

    @include mobile {
      margin-left: -$side-menu-width-mobile;
    }    
  }

  .xgs-menu {
    width: $side-menu-width-desktop;
    min-height: $height-without-header;
    height: 100%;
    background-color: $side-menu-back;
    display: flex;
    flex-direction: column;

    @include tablet {
      width: $side-menu-width-tablet;
    }

    @include mobile {
      display: block;
      width: $side-menu-width-mobile;
      height: $height-without-header;
      overflow-y: scroll;
    }

    &__list {
      display: flex;
      flex-direction: column;
      padding: $margin6 0 0 $margin5;

      @include mobile {
        padding: $margin2 $margin5;
        margin: 0 auto $margin1 auto;
        max-width: 240px;
      }
    }

    &__item {
      display: flex;      
      width: 100%;
      height: $margin5;
      text-decoration: none;
      color: $side-menu-item-color;
      font-size: 15px;
      background-color: $side-menu-item-back;

      &--link:hover,
      &--link:hover .xgs-menu__icon,
      &__sub:hover {
        color: $blue1;
      }

      &__selected,
      &__selected .xgs-menu__icon {
        color: $side-menu-item-selected-color;
      }

      &:not(.xgs-menu__item__selected) .xgs-menu__item__sub {
        color: $gray3;
      }

      &--desktop-hide {
        @media only screen and (min-width: $tablet-width + 1) {
          display: none;
        }
      }

      @include mobile {
        font-size: 18px;
        height: $margin4;
      }
    }

    &__icon-container {
      display: flex;
      justify-content: flex-end;
      width: 24px;
    }

    &__icon {
      margin: 0 $margin8 0 0;
      color: $gray1;
    }

    &__sub-account-selector {
      display: none;
      margin-bottom: 24px;

      @include up-to(600px) {
        display: block;
      }

      .popup-content {
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
      }
    }
  }
}