@import "./../../styles/mixins.scss";
@import "./../../styles/variables.scss";

.xgs-pod-information {

  &__select-all {
    text-align: center;
  }

  &__buttons {
    margin-bottom: $margin6;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    a {
      font-size: 15px;
      color: $blue1;
      display: flex;
      align-items: center;

      @include up-to(470px) {
        width: 100%;
        
      }
    }

    button {
      @include up-to(470px) {
        width: 100%;
      }
    }
  }
}
