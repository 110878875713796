@import "../../styles/variables.scss";

.exact-freight-list {
  margin-top: $margin7;
  &__actions-header {
    text-align: center;
  }
  &__button-cell {
    display: flex;
    button {
      font-size: 12px;
      width: 75px;
      margin-right: 5px;
    }
  }
  &__toolbar {
    padding: 0px 0 24px 0;
    // width: 200px;
    text-align: center;
    &__button {
      margin: 0 auto;
      width: 90%;
      max-width: 320px;
      margin-bottom: 24px;
      
    }
  }
  &__search {
    display: flex;
    max-width: fit-content;
    gap: 10px;
    margin-bottom: $margin4;
    &__button {
      margin-top: 23px;
      height: 35px;
    }
  }
}