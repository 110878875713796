@import "./../../styles/variables.scss";

.xgs-table__wrapper {
  position: relative;
  display: block;
  height: 100%;
  overflow-x: auto;

  &--loading {
    overflow-x: visible;
  }
}

.xgs-table {
  position: relative;
  // display: flex;
  // justify-content: stretch;
  // flex-wrap: wrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
  overflow-x: auto;
  overflow-y: hidden;

  &__headers {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $gray6;

    .th {
      color: $gray2;
      font-weight: bold;
    }

    &--sorting {
      .th:hover {
        cursor: pointer;
        color: $gray3;
      }
    }

    &__sortable-item {
      position: relative;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &__icon {
        position: absolute;
        right: 16px;
        top: 7px;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .th,
  .td {
    font-size: 14px;
    margin: 0;
    padding: 0.5rem;
    flex-shrink: 0;
    word-wrap: break-word;
  }

  .tr {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  &__full-width {
    width: 100% !important;

    & > div {
      width: 100% !important;      
    }

    .td,
    .th {
      flex-shrink: 1 !important;
    }
  }

  &__scroll-spacer {
    width: 16px;
    flex-shrink: 0 !important;
  }

  &__even-row {
    background-color: #fafafa;
  }

  &__highlighted-row {
    background-color: $gray5;
  }

  &__spinner-row {
    .td {
      width: 100%;
      text-align: center;
    }
  }

  .tr:not(.xgs-table__headers):not(.xgs-table__spinner-row):hover {
    background-color: #eeeeee;
  }

  &__loading-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 998;
  }

  &__loading {
    height: 120px;
    width: 100%;
    text-align: center;
    padding-top: 50px;

    &--over {
      position: absolute;
      left: calc(50% - 16px);
      top: 80px;
      z-index: 999;
      width: auto;
    }
  }

  &__no-records {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}