@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: $margin6;

  &__user {
    position: relative;
    margin-right: $margin6;
    max-width: 180px;
    max-height: $header-height;
    white-space: nowrap;

    &--normal {
      display: block;

      @include mobile {
        display: none;
      }
    }

    &--short {      
      display: none;
      width: 40px;
      height: 40px;
      background-color: $gray5;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;

      @include mobile {
        display: block;
      }
    }

    &__name {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;

      &__impersonated {
        color: $purple1;
        margin-right: 8px;

        &--mobile {
          position: absolute;
          right: 20px;
        }
      }
    }

    &__team {
      margin-top: 4px;
      font-size: 14px;
      color: $gray4;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include tablet {
      margin-right: $margin7;
      max-width: 160px;
      font-size: 14px;
    }

    @include mobile {
      margin-right: $margin8;
      max-width: 40px;
      font-size: 16px;
    }
  }

  &__arrow {
    color: $gray1;

    svg {
      font-size: 14px;

      @include tablet {
        font-size: 12px;
      }

      @include mobile {
        font-size: 11px;
      }
    }

    @include tablet {
      padding-bottom: 4px;
    }

    @include mobile {
      padding-bottom: 2px;
    }    
  }
}

.xgs-userpick {
  color: $purple1;
  line-height: 22px;
}