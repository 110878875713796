@import "./../../../styles/variables.scss";

.xgs-container-status-header {
  display: flex;
  flex-direction: row;

  .iconblock {
    margin-right: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .xgs-icon {
      font-size: 24px;
    }
  }
  

  .infoblock {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;     

    label {
      font-size: 12px;
      line-height: 14px;
      color: $gray3;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    span {
      font-size: 16px;
      line-height: 16px;
      color: $gray1;
      position: relative;
    }
  }
}
