@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-slide-out-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60vw;
  max-width: 820px;
  background-color: white;
  transition: margin 0.5s ease-in-out;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  @include up-to(1324px) {
    width: 65vw;
  }

  @include up-to(1024px) {
    width: 78vw;
  }

  @include mobile {
    width: 100vw;
    max-width: 100vw;    
  }

  &--opened {
    margin-right: 0;
  }

  &--closed {
    margin-right: -820px;
  }

  &__header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 54px;
    background-color: $gray6;
    padding: 18px 26px;
  }

  &__spinner {
    display: inline-block;
  }

  &__close {
    color: $gray4;
    cursor: pointer;
  }

  &__content {
    box-sizing: border-box;
    height: 100%;
    max-height: calc(100vh - 54px);
    overflow-y: auto;
    padding: 26px 32px 26px 28px;
  }
}