@import "./../../../styles/mixins.scss";
@import "./../../../styles/variables.scss";

.xgs-shipment-details {
  max-width: 1000px;
  &__add-note {
    padding: 10px;
    &__open-button {
      margin-left: 6px;
      color: $blue1;
      font-size: 23px;
      &:hover {
        font-size: 24px;
      }
    }

    &__text {
      max-width: 500px;
    }
    &__button {
      width: 200px;
      margin-top: $margin7;
    }
  }
  &__header {
    &__exception-icon {
      height: 40px;
      background-color: blue;
    }

    .xgs-site__content-container-header__bar {
      flex-grow: 1;

      @include up-to(1388px) {
        width: 100%;
        margin-top: 16px;
      }
    }

    &__status {
      max-width: 250px;

      @include up-to(1212px) {
        max-width: 400px;
      }

      @include up-to(680px) {
        max-width: 250px;
      }

      @include up-to(420px) {
        max-width: none;
        width: 100%;
      }
    }
  }

  &__subscribe {
    &__button {
      cursor: pointer;
      text-align: center;
      margin-right: 16px;

      &--unsubscribe {
        color: $red;
      }

      &__icon {
        font-size: 18px;
      }

      &__label {
        font-size: 11px;
        line-height: 12px;
        margin-top: 7px;
      }
    }

    &__confirmation__text {
      line-height: 1.4;
    }
  }

  &__grid {
    max-width: 908px;
    &__column {
      width: 288px;

      @include up-to(692px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &__double-column-wrapper {
      @include up-to(692px) {
        flex-grow: 1;
      }
    }

    &__double-column {
      .xgs-info-block__container {
        padding: 6px;
      }

      &__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }

      .xgs-labled-value {
        padding: 4px;        
      }
    }
  }

  .xgs-info-block {
    @include up-to(692px) {
      width: 100%;
    }    
  }

  &__pay-invoice {
    width: 220px;
    padding-right: 24px;
    margin-right: 0;

    @include up-to(642px) {
      width: 100%;
      padding-right: 0;
    }

    .xgs-btn {
      margin-left: 16px;

      @include up-to(642px) {
        width: 100%;
        flex-shrink: 1;
        margin-left: 16px;
      }
    }
  }

  &__no-pod {
    width: 572px;
    text-align: center;

    @include up-to(692px) {
      width: 100%;
    }

    a {
      padding: 5px;
      margin-left: $margin8;
    }
  }

  &__tabs {
    margin: 36px 0;
    max-width: 895px;

    .react-tabs__tab-list {
      margin-bottom: 4px;
    }

    &__no-data {
      text-align: center;
      font-size: 14px;
      min-height: 60px;
      line-height: 60px;
      color: $gray2;
    }

    .react-tabs__tab-panel {
      padding: 10px;
      box-sizing: content-box;
      background: $gray6;
      border-radius: 8px;
      max-height: 320px;
      overflow-y: auto;
    }


    .react-tabs__tab {
      @include up-to(590px) {
        width: 100%;
        border: 1px solid #2F80ED !important;
        border-radius: 0;

        &:first-child {
          border-radius: 4px 4px 0 0;
        }

        &:last-child {
          border-radius: 0 0 4px 4px;
        }
      }
    }    
  }

  &__dock-log {
    max-height: 298px;
    height: 100%;
  }

  &__status-history {
    max-height: 298px;
    height: 100%;
  }

  &__exception-history {
    max-height: 298px;
    height: 100%;
  }

  &__appt-history {
    max-height: 298px;
    height: 100%;
  }
}