@import "./../../styles/variables.scss";

.xgs-page-loading {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: $header-height;
  height: $height-without-header;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $content-page-loading-background;
  z-index: 1;
}