@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-site__content-container__toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 100%;
  // max-width: 1100px;
  margin-right: 24px;
  
  &__buttons {
    display: flex;

    a,
    &__item {
      cursor: pointer;
      color: $gray3;
      text-decoration: none;

      &:not(:first-child) {
        margin-left: 16px;
      }

      &__icon {
        font-size: 18px;
      }

      &__text {
        font-size: 12px;

        &--w-icon {
          margin-left: 4px;
        }
      }

      &:hover {
        color: $blue1;
      }
    }
  }

  &__button {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    color: $gray3;

    &:hover {
      color: $blue1;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }    

    &__icon {
      font-size: 18px;
    }

    &__label {
      font-size: 12px;
      line-height: 12px;
      margin-top: 7px;
    }
  }  
}
