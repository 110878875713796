@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

$color-base: $gray3;
$color-reached: $green3;
$color-current: $green1;
$vertical-breakpoint: 898px;

.xgs-stepped-progress {
  display: flex;
  width: 868px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  z-index: 1;

  li {
    list-style-type: none;
    color: $gray1;
    line-height: normal;
    flex: 1;
    font-size: 14px;
    position: relative;
    padding: 0 2%;

    &:before {
      content: " ";
      padding-top: 2px;
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      display: block;
      color: white;
      background: $color-base;
      border-radius: 15px;
      margin: 0 auto 10px;
      position: relative;
      z-index: 1;
    }

    /* connectors */
    &:after {
      content: '';
      width: 100%;
      height: 6px;
      background: lighten($color-base, 25);
      position: absolute;
      left: -50%;
      top: 10px;
      z-index: -1;
    }

    &:first-child:after {
      content: none;
    }

    &.current .xgs-stepped-progress__step__header {
      font-weight: bold;
    }

    &.completed:before {
      content: "\2714";
    }

    &.reached {
      &:not(.current):before {
        background: $color-reached;
        color: white;
      }

      &.current:before {
        background: $color-current;
        color: white;
      }

      &:after {
        background: transparentize($color-reached, 0.4);
      }
    }
  }

  &__step__subheader {
    margin-top: 4px;
    color: $gray4;
  }

  @media (max-width: $vertical-breakpoint) {
    display: block;

    li {
      flex: none;
      clear: both;
      text-align: left;
      padding: 0 0 16px 0;
      margin-left: 0;
      min-height: 2.2em;
      font-size: 16px;

      &:before {
        float: none;
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        margin-left: 0;
      }

      &:after {
        content: "";
        width: 6px;
        height: 100%;
        position: absolute;
        left: 9px;
        top: -100%;
        z-index: -1;
      }
    }

    &__step { 
      &__header {
        display: block;
        margin: -36px 0 0 36px;
        white-space: nowrap;
      }

      &__subheader {
        margin-left: 36px;
        font-size: 14px;
      }
    }    
  }


  .xgs-trailer-locations__open__container--text-mode {
    @include up-to(769px) {
      margin-left: 36px;
    }
  }

}
