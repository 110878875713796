@import "./../../../styles/mixins.scss";
@import "./../../../styles/variables.scss";

.xgs-enter-pod{
  
  &__form {
    overflow-x: auto;
    overflow-y: auto;
    max-width: fit-content;
    height:  calc(100vh - 250px);

    .xgs-select__indicator { // remove indicator
      display: none;
    }

    .xgs-labeled-input__validation-error {
      font-size: 11px;
    }
    
    &__controls {
      font-size: 30px;
      width: min(100%, 900px);
      display: flex;
      gap: 20px;
      justify-content: space-between;

      position: relative;
      right: 0;

      &__list {
        display: flex;
        gap: 20px
      }
      svg {
        margin-right: $margin8;
      }
      &__push {
        color: $blue1;
      }

      &__pop {
        color: $gray3;

        &--disabled {
          color: $gray5;
        }
      }
    }

    &__row {
      display: flex;
      padding: 12px;
      flex-wrap: nowrap;
      gap: 14px;
      width: min-content;

      &__serial {
        align-items: center;
        display: flex;
      }

      &:nth-child(even) {
        background-color: #eeeeee;
      }

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      > * {
        flex-shrink: 0;
      }

      .xgs-select {
        &__control {
          font-size: 13px !important;
        }
        &__menu {
          font-size: 13px;
          width: 100px
        }
      }

      label {
        font-size: 12px;
      }

      input {
        font-size: 13px;
      }
  
      &__date {
        position: relative;
        width: 80px;
      }
  
      &__del-time {
        position: relative;
        width: 100px;
      }

      &__late-code {
          width: 145px;
          .xgs-select__control {
            font-size: 12px !important;
          }
          .xgs-select__indicator { // make indicator smaller
            position: absolute !important;
            right: -1px;
            opacity: 0.8;
          }
          .xgs-select__menu {
            width: 145px;
          }
      }

      &__item {
        position: relative;
        width: 80px;
      }
  
      &__comment {
        position: relative;
        width: 150px;
      }
  
      &__small-item {
        position: relative;
        width: 60px;
        flex-shrink: 0;
      }
      
      &__upload-item {
        width: 280px;
  
        @include up-to(470px) {
          width: 100%;
        }
      }
  
      &__date-item {
        position: relative;
        width: 200px;
        flex-shrink: 0;
    
        @include up-to(470px) {
          width: 100%;
        }
      }
    }
  }
}
