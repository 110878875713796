@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.add-freight-exception {
  .xgs-invoice-details__table__title {
    font-size: 14px;
    margin: 0px 0px 3px 0px;
  }

  &__history {
    background: $gray6;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    
    &__table {
      height: 100%;
    }
  }

  &__form {
    button {
      margin-top: $margin7;
      margin-bottom: 150px;
      @include up-to(470px) {
        width: 100%;
      }
    }

    &__item {
      margin-top: $margin7;
      width: 400px;

      @include up-to(470px) {
        width: 100%;
      }
    }
    &__upload {
      margin-top: $margin7;
      &__header {
        font-size: 14px;
        margin-bottom: 3px;

      }
      &__message {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 4px;
      }
      &__area {
        background-color: $gray5;
        height: 85px;
        border-radius: 1px;
        padding: 5px;
        width: 400px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        @include up-to(470px) {
          width: 100%;
        }
      }
    }

  }
  // &__form-item {
  //   margin-top: $margin7;
  //   width: 400px;

  //   @include up-to(470px) {
  //     width: 100%;
  //   }
  // }
}

.select-freight-exception{

  &__select-form {
    width: 250px;
    input {
      width: 17px;
      height: 17px;
      border: 1px solid $gray3;
      border-radius: 4px;
      flex-shrink: 0;
    }

  }
  &__submit-button {
    margin-top: 20px;
  }

  &__recent {
    &__heading {
      margin-top: $margin4;
      margin-bottom: $margin7;
    }
  }
}