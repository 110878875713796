@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-filters-toggle {
  margin-right: -24px;
  padding: 0 0 8px 8px;

  &__icon {
    cursor: pointer;
    color: $gray3;
    font-size: 14px;
  }

  &__filters {
    &--show {
      max-height: 760px;
      opacity: 1;
      transition: all 1s ease;
    }

    &--hide {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 1s ease;
    }
  }
}
