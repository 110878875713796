@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.inbound-freight {
  &__slidein {
    width: 30vw;
    @include up-to(1324px) {
      width: 35vw;
    }
  
    @include up-to(1024px) {
      width: 38vw;
    }
  
    @include mobile {
      width: 100vw;
      max-width: 100vw;    
    }
  }

  &__toolbar {
    padding: 0px 0 24px 0;
    text-align: center;
    a {
      text-decoration: none;
    }
    &__heading {
      margin-top: 15vh;
    }
    &__button {
      margin: 0 auto;
      display:block;
      max-width: 320px;
      margin-bottom: 24px;
      
    }
  }
  &__header {
    display: flex;
    gap: 20px;
  }
  &__form {
    margin-bottom: $margin4;
    &__fields {
      display: flex;
      gap: 20px;
      max-width: 600px;
    }
    &__buttons {
      margin-top: $margin7;
      display: flex;
      gap: 10px;
      width: fit-content;
    }
  }
}

.inbound-freight-list {
  margin-top: $margin7;
  &__cell-hover {
    position: relative;
    left: 45px;
    top: -20px;
    background-color: #cce6ff;
    padding-left: 15px;
    padding-right: 15px;
    padding: 5px 15px 5px 15px;
    width: 350px;
    a{
      display: block;
      font-size: 13px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
  &__hot {
    margin-left: 7px;
    size: 10px;
    cursor: pointer;
  }
  &__hot-rush-hover {
    position: relative;
    left: 68px;
    top: -1px;
    background-color: #cce6ff;
    padding-left: 15px;
    padding-right: 15px;
    padding: 5px 15px 5px 15px;
    width: 100px;
  }
  &__highlight-row {
    background-color: $blue1;
    height: 100px;
    margin-bottom: 10px;
  }
}
