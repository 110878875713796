@import "./../../styles/variables.scss";

.xgs-breadcrumbs {
  font-size: 14px;
  display: flex;

  &__item {
    color: $link-color;
    text-decoration: none;
    padding: 0 2px;

    &:hover {
      color: $blue1;
    }
  }

  &__separator {
    margin: 0 4px;
  }
}