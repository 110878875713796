@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-site__content-container-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 22px;

  @include mobile {
    align-items: center;
  }

  &__bar {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &__float-buttons {
      position: absolute;
      right: 16px;
      display: flex;

      @include up-to(420px) {
        width: 100%;
        position: relative;
        justify-content: center;
        padding-left: 24px;
        flex-wrap: wrap;
      }

      &__item:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__button {
      cursor: pointer;
      display: inline-block;
      text-align: center;

      &__icon {
        font-size: 18px;
      }

      &__label {
        font-size: 11px;
        line-height: 12px;
        margin-top: 7px;
      }
    }
  }

  &__column {
    $column-width: 288px;
    display: flex;
    width: $column-width;
    margin-right: 16px;

    @include up-to(997px) {
      &:not(.xgs-site__content-container-header__column--mobile-alone) {
        margin-bottom: 16px;
      }
    }

    .xgs-site__content-container-header > &:not(.xgs-site__content-container-header__column--mobile-alone) {
      width: calc(#{$column-width} + 16px);

      @include mobile {
        max-width: 200px;
      }
    }

    &--between {
      justify-content: space-between;
    }

    &__toolbar {
      display: flex;
      justify-content: flex-end;
      margin-top: -24px;      
    }
  }

  &__start-content {
    width: auto;
    font-size: 18px;

    display: flex;
    flex-direction: row;

    span {
      margin-right: $margin7;
    }
  }

  &__middle-content {
    display: flex;
    justify-content: flex-start;
    margin-left: 21%;
  }
}