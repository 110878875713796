@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-info-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-basis: auto;
  justify-content: flex-start;

  & > div {
    margin-bottom: $margin5;
    margin-right: $margin7;

    @include up-to(692px) {
      margin-right: 0;
    }
  }
}