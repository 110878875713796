@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.update-load-manifest {
  
  &__form {
    display: flex;
    gap: $margin7;
    flex-wrap: wrap;
    max-width: 640px;
    
    button {
      @include up-to(470px) {
        width: 100%;
      }
    }

    &__info {
      margin-bottom: $margin7;
      width: fit-content;
      @include up-to(470px) {
        width: 100%;
      }
    }

    &__field {
      width: 200px;
      flex-shrink: 0;

      @include up-to(470px) {
        width: 100%;
      }
    }
    &__submit {
      margin-top: $margin6;
      margin-bottom: 150px;

      @include up-to(470px) {
        width: 100%;
      }
    }
  }
}
