@import "./../../../styles/variables.scss";

.xgs-table__totals {
  width: 100%;
  font-weight: bold;
  padding: 0.5rem;
  border-top: 1px solid rgb(189, 189, 189);
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
  letter-spacing: 0.5px;

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;

    & + .xgs-table__totals__row {
      margin-top: 8px;
    }
  }

  &__title {
    margin-right: 10px;
  }

  &__value {
    min-width: 60px;
  }
}

.rdt_TableCell div:first-child {
  overflow: inherit !important;
}
