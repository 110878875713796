@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-popup {
  overflow-y: auto;
  background-color: $gray7;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-family: "Roboto-Bold";
    font-size: 20px;
  }

  &__close {
    color: $gray4;
    cursor: pointer;

    &:hover {
      color: $gray3;
    }
  }

  &__content {
    padding: $margin6;

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}