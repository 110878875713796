@import "./../../../../styles/variables.scss";
@import "./../../../../styles/mixins.scss";

.xgs-invoice-documents {
  width: 100%;
  max-width: 895px;

  .xgs-info-block__container {
    padding-bottom: 0;

    & > div {
      padding-bottom: 0;
    }
  }

  &__loading {
    margin: $margin7 $margin6 $margin6 $margin6;
    text-align: center;
  }

  &__error {
    color: $red;
    font-size: 14px;
    margin: $margin7 $margin6 $margin6 $margin6;
    text-align: center;
  }

  &__no-items {
    color: $gray2;
    font-size: 14px;
    margin: $margin7 $margin6 $margin6 $margin6;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: $margin8;
    margin-left: $margin6;

    @include up-to(450px) {
      justify-content: center;
      margin-left: 0;
    }
  }

  &__item {
    position: relative;
    margin-bottom: $margin6;

    &:not(:last-child) {
      margin-right: $margin5;

      @include up-to(424px) {
        margin-right: 0;
      }
    }

    @include up-to(424px) {
      &.xgs-invoice-documents__item--separated:not(:last-child) {
        margin-right: 0;
      }
    }

    @include up-to(424px) {
      width: 100%;
    }

    &--separated {
      padding-right: 36px;
      border-right: 1px solid #c0c0c0;

      @include up-to(424px) {
        width: 100%;
        padding-right: 0;
        border-right: none;
      }
    }

    &__header {
      font-weight: bold;
      text-align: center;
    }

    &__image {
      position: relative;
      text-align: center;
      margin: $margin8 0 4px 0;

      &--fixed-width {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 77px;
        height: 100px;
      }

      &__no-thumbnail {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $gray5;
        font-size: 12px;
        color: $gray2;
      }
    }

    &__links {
      color: $gray4;
      text-align: center;

      &--disabled {
        color: $gray4;
        
        .xgs-invoice-documents__item__link {
          color: $gray4;
          cursor: inherit;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &__link {
      color: $blue1;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        text-decoration: underline;        
      }
    }

    &__loading {
      position: absolute;
      left: 14px;
      top: 40px;

      &--offset {
        left: 24px;
      }
    }

    &__all {
      &__icon {
        height: 100%;
        width: auto;
        color: $gray3;

        &--spinner {
          color: $gray4;
        }
      }
    }
  }
}