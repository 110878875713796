@import "./../../styles/mixins.scss";

.delivery-receipts {
  margin-top: -15px;
  width: 240px;
  * {

    label{
      margin-top: 15px;
    }
    
  }
  &__tip {
    margin: 0;
    margin-left: -15;
    font-size: 10;

  }
  &__button{
    width: 150px;
    text-align: center;
    margin-top: 30px;
    *{
      text-align: center;
    }
  }
  &__success {
    margin: 32px auto;
    text-align: center;
    line-height: 1.5;

    &__start-over-button {
      margin-top: 16px;

      @include up-to(420px) {
        width: 100%;
      }
    }
  }
}