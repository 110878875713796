@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-site__content-container {
  padding-top: $margin7;
  padding-right: 60px;

  @include mobile {
    padding-right: 24px;
  }

  .xgs-loading {
    margin-left: $margin7;
  }

  .xgs-btn--spinner .xgs-loading {
    margin-left: 0;
  }

  &__component {
    display: flex;
    flex-direction: column;
    margin: $margin4 0 0 calc(#{$margin4} - #{$margin6});

    @include mobile {
      margin: $margin7 0 0 0;
    }
  }

  &__header {

  }
}
