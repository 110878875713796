@import "./../../styles/variables.scss";

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    margin: 0 0 32px 0;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    position: relative;
    list-style: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    color: $gray1;
    box-sizing: border-box;
    border: 1px solid $blue1;
    border-right: none;
    height: 32px;
    width: 127px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-radius: 4px 0px 0px 4px;      
    }

    &:last-child {
      border-right: 1px solid $blue1;
      border-radius: 0px 4px 4px 0px;
    }

    &--selected {
      background: $lightblue;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
