@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.roll-details {

  &__no-item-message {
    background-color: $gray7;
    color: $gray1;
    padding: 18px;
    width: 950px;
    text-align: center;
    @include up-to(1200px) {
      width: 100%;
    }
  }

  &__search {
    &__probill {
      width: 200px;
      margin-bottom: $margin5;
      @include up-to(450px) {
        width: 100%;
      }
    }
  }

  &__accordion-wrapper {
    max-height: 80vh;
    overflow-y: auto;

    .accordion__button {
      min-width: 1050px;
    }
  }
  &__table-wrapper {
    width: fit-content;
    @include up-to(1200px) {
      width: 100%;
    }
  }
  
  &__item-row {
    display: flex;
    width: 100%;
  }

  &__table {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    &__headers {
      display: flex;
      width: 100%;
      align-items: center;
      background-color: $gray6;
  
      .th {
        color: $gray2;
        font-weight: bold;
      }
    }
  }

  &__th,
  &__td {
    width: 100px;
    text-align: left;
    font-size: 13px;
    padding-left: $margin8;
    &__roll {
      width: 120px;
    }
    &__desc {
      width: 120px;
    }
    &__quantity {
      width: 55px;
    }
    &__color {
      width: 100px;
    }
    &__style {
      width: 110px;
      white-space: wrap;
    }
    &__weight {
      width: 65px
    }
    &__yards {
      width: 80px;
    }
    &__dyelot {
      width: 110px;
    }
    &__dimensions {
      width: 80px;
    }
  }
  
  &__item-row:nth-child(even) {
    background-color: #fafafa;
  }
  
  &__item-row:nth-child(odd) {
    background-color: #ffffff;
  }
  &__icon-link {
    cursor: pointer;
    margin-left: 5px;
    text-decoration: none; /* remove underline from anchor tag */
  }
  
  &__link-icon {
    color: $blue1; /* initial color of the icon */
    transition: all 0.1s ease-in-out; /* for smooth color transition */
  }
  
  &__icon-link:hover &__link-icon {
    color: blue; /* color on hover */
    transform: scale(1.2); /* scale on hover */
  }

  &__download-csv {
    position: absolute;
    top: 42px;
    left: 980px;

    @include up-to(1180px) {
      left: auto;
      right: 110px;
    }

    @include up-to(768px) {
      right: 80px;
    }

    @include up-to(400px) {
      position: relative;
      top: auto;
      right: auto;
      margin-bottom: 24px;
      text-align: center;
    }

    &__spinner {
      margin-left: -4px !important;
    }
  }
}
