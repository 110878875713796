@import "./../../styles/variables.scss";

.xgs-info-block {
  &__header {
    font-family: "Roboto-Medium";
    margin-bottom: $margin8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    &__title {
      color: $gray2;
      font-size: 14px;
      line-height: 16px;
    }

    &__info {
      display: flex;
      align-items: center;
      font-size: 16px;

      .svg-inline--fa {
        font-size: 12px;
        line-height: 14px;
        color: $gray3;
        margin-right: 5px;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: $gray6;
    border-radius: 8px;

    .common-text {
      font-size: 14px;
      color: $gray2;
    }

    .pre-wrap {
      white-space: pre-wrap;
    }

    & > div {
      padding: 4px;
    }
  }
}