@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.change-appointment {
  &__history {
    background: $gray6;
    border-radius: 8px;
    padding: 10px;
    width: 100%
    &__table {
      height: 100%;
    }
  }

  &__probill-select {
    width: 250px;
  }

  &__form {
    display: flex;
    gap: $margin7;
    flex-wrap: wrap;
    max-width: 640px;
    margin-bottom: $margin7;
    
    &__field {
      max-width: 400px;
      width: 200px;

      @include up-to(920px) {
        width: calc(50% - 20px);
      }

      @include up-to(470px) {
        width: 100%;
      }
    }
  }

  &__submit {
    @include up-to(470px) {
      width: 100%;
    }
  }
}
